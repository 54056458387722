.fw {
  &-regular {
    font-weight: $font-weight-regular;
  }

  &-semi-bold {
    font-weight: $font-weight-semi-bold;
  }

  &-bold {
    font-weight: $font-weight-bold;
  }

  &-extra-bold {
    font-weight: $font-weight-extra-bold;
  }
}

.ff {
  &-body {
    font-family: $default-font-family;
  }

  &-heading {
    font-family: $headings-font-family;
  }
}

.fs {
  &-xs {
    font-size: $font-size-xs;
  }

  &-sm {
    font-size: $font-size-sm;
  }

  &-normal {
    font-size: $default-font-size;
  }

  &-lg {
    font-size: $font-size-lg;
  }

  &-xl {
    font-size: $font-size-xl;
  }
}

.text {
  &-transform {

    &-initial {
      text-transform: initial;
    }
  }

  &-pre-line {
    white-space: pre-line;
  }

  &-decoration {

    &-none {
      text-decoration: none;
    }

    &-underline {
      text-decoration: underline;
    }
  }
}
