:root {
  --font-weight-thin: 100;
  --font-weight-extra-light: 200;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;
  --font-weight-extra-bold: 800;
  --font-weight-black: 900;
  --font-size-default: 1rem;
  --font-size-xs: 0.75rem;
  --font-size-sm: 0.875rem;
  --font-size-lg: 1.25rem;
  --font-size-xl: 1.75rem;
  --h1-font-size: 2.125rem;
  --h2-font-size: 1.75rem;
  --h3-font-size: 1.25rem;
  --h4-font-size: 0.875rem;
  --headings-font-family: Open Sans, sans-serif;
  --headings-font-weight: 600;
  --headings-color: #561F05;
}

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #561F05;
  --ion-color-primary-rgb: 86,31,5;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #4c1b04;
  --ion-color-primary-tint: #67351e;
  /** secondary **/
  --ion-color-secondary: #AF8C4B;
  --ion-color-secondary-rgb: 175,140,75;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #9a7b42;
  --ion-color-secondary-tint: #FBF6EC;
  /** tertiary **/
  --ion-color-tertiary: #D31212;
  --ion-color-tertiary-rgb: 211,18,18;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #ba1010;
  --ion-color-tertiary-tint: #d72a2a;
  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;
  /** warning **/
  --ion-color-warning: #BFA36F;
  --ion-color-warning-rgb: 191,163,111;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #a88f62;
  --ion-color-warning-tint: #c5ac7d;
  /** danger **/
  --ion-color-danger: #D32A3A;
  --ion-color-danger-rgb: 211,42,58;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #ba2533;
  --ion-color-danger-tint: #d73f4e;
  /** dark **/
  --ion-color-dark: #595959;
  --ion-color-dark-rgb: 89,89,89;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #4e4e4e;
  --ion-color-dark-tint: #6a6a6a;
  /** medium **/
  --ion-color-medium: #A1A1A1;
  --ion-color-medium-rgb: 161,161,161;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #8e8e8e;
  --ion-color-medium-tint: #aaaaaa;
  /** light **/
  --ion-color-light: #FFFFFF;
  --ion-color-light-rgb: 255,255,255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #e0e0e0;
  --ion-color-light-tint: #ffffff;
  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;
  --theme-border-radius: 0.5rem;
  --theme-border-radius-lg: 0.75rem;
  --theme-border-radius-circle: 50rem;
  --theme-border-width: 1px;
  --theme-border-style: solid;
  --theme-border-color: #ffffff;
  --theme-padding: 1rem;
  --theme-margin: 1rem;
  --ion-button-font-weight: 700;
  --ion-button-font-size: 0.875rem;
  --ion-button-text-transform: uppercase;
  --ion-button-border-radius: .5rem;
  --ion-button-border-radius-circle: 50rem;
  --ion-button-border-width: var(--theme-border-width);
  --ion-button-box-shadow: none;
  --ion-button-padding-x: 1.5rem;
  --ion-button-padding-y: var(--ion-button-padding-x);
  --ion-button-color: var(--ion-color-secondary);
  --ion-button-background: #ffffff;
  --form-input-bg: var(--ion-color-light);
  --form-input-bg-over-color: transparent;
  --form-input-color: var(--ion-color-dark);
  --form-input-color-over-color: var(--ion-color-light);
  --form-input-border-color: var(--form-input-color);
  --form-input-border-width: var(--theme-border-width);
  --form-input-border-style: var(--theme-border-style);
  --form-input-border-radius: var(--theme-border-radius);
  --form-input-box-shadow: 0 4px 8px rgba(var(--ion-color-medium-contrast-rgb), .08);
  --form-input-placeholder-color: var(--ion-color-medium);
  --ion-label-font-size: 0.75rem;
  --ion-input-background: var(--form-input-bg);
  --ion-input-color: var(--form-input-color);
  --ion-input-border-width: var(--form-input-border-width);
  --ion-input-border-color: var(--form-input-border-color);
  --ion-input-border-style: var(--form-input-border-style);
  --ion-input-border-radius: var(--form-input-border-radius);
  --form-ion-input-bg-over-color: var(--form-input-bg-over-color);
  --ion-input-color-over-color: var(--ion-color-white);
  --ion-input-placeholder-color-over-color: var(--ion-color-white);
  --ion-input-padding-start: 1rem;
  --ion-input-padding-end: 1rem;
  --ion-checkbox-border-width: var(--form-input-border-width);
  --ion-checkbox-border-style: var(--form-input-border-style);
  --ion-checkbox-border-radius: var(--form-input-border-radius);
  --ion-checkbox-bg-over-color: var(--form-input-bg-over-color);
  --ion-checkbox-border-color-over-color: inherit;
  --ion-radio-mark-size: 4px;
  --ion-radio-background: transparent;
  --ion-radio-background-checked: var(--ion-color-warning);
  --ion-radio-color-checked: var(--ion-color-dark);
  --ion-radio-background-correct: var(--ion-color-primary);
  --ion-radio-background-wrong: var(--ion-color-danger);
  --ion-select-color: var(--ion-input-color);
  --ion-select-background: var(--ion-input-background);
  --ion-select-border-width: var(--form-input-border-width);
  --ion-select-border-color: var(--ion-input-border-color);
  --ion-select-border-style: var(--form-input-border-style);
  --ion-select-border-radius: var(--form-input-border-radius);
  --ion-item-background: rgba(var(--ion-color-primary-rgb), 0);
}

html {
  /*
   * For more information on dynamic font scaling, visit the documentation:
   * https://ionicframework.com/docs/layout/dynamic-font-scaling
   */
  --ion-dynamic-font: var(--ion-default-dynamic-font);
}

b {
  font-weight: var(--font-weight-medium);
}

html, html.ios, html.md {
  --ion-default-font: Open Sans, sans-serif;
}

.can-go-back ion-header ion-back-button {
  display: block;
}

ion-accordion {
  border-radius: var(--theme-border-radius);
  border: var(--theme-border-width) var(--theme-border-style) var(--ion-accordion-border-color);
  margin-bottom: 1rem;
}
ion-accordion [slot=header] ion-avatar {
  --border-radius: var(--theme-border-radius);
}
ion-accordion.accordion-expanded [slot=header], ion-accordion.accordion-expanding [slot=header] {
  font-weight: 500;
}
ion-accordion::part(header) {
  border-radius: var(--theme-border-radius);
}
ion-accordion::part(header expanded) {
  border-bottom: var(--theme-border-width) var(--theme-border-style) var(--ion-accordion-active-color);
}
ion-accordion::part(content) {
  --padding-start: 0;
  --inner-padding-end: 0;
}
ion-action-sheet.custom {
  --button-color-selected: var(--ion-color-secondary);
}

ion-badge {
  --padding-top: var(--ion-badge-padding-y);
  --padding-end: var(--ion-badge-padding-x);
  --padding-bottom: var(--ion-badge-padding-y);
  --padding-start: var(--ion-badge-padding-x);
  border-radius: var(--ion-badge-border-radius);
}
ion-badge:not(:last-child), ion-badge:not(:last-of-type) {
  margin-right: 0.5rem;
}

ion-button {
  --background: var(--ion-button-background);
  --border-radius: var(--ion-button-border-radius);
  --border-width: var(--ion-button-border-width);
  --box-shadow: "";
  --color: var(--ion-button-color);
  --padding-bottom: 1rem;
  --padding-end: 1.5rem;
  --padding-start: 1.5rem;
  --padding-top: 1rem;
  text-transform: var(--ion-button-text-transform);
  font-weight: var(--ion-button-font-weight);
}
ion-button.button-disabled {
  color: var(--ion-color-medium);
}
ion-button:not(.ion-no-padding):not(.button-small):not(.button-large) {
  height: auto;
  font-size: var(--ion-button-font-size);
  --padding-top: var(--ion-button-padding-y);
  --padding-end: var(--ion-button-padding-x);
  --padding-bottom: var(--ion-button-padding-y);
  --padding-start: var(--ion-button-padding-x);
}
ion-button.outline {
  --background: var(--ion-background-color);
  --border-color: var(--ion-text-color);
  --border-style: solid;
  --border-width: 1px;
  --color: var(--ion-text-color);
}
ion-button.text-link {
  --ion-button-padding-x: 0;
  vertical-align: middle;
  position: relative;
  top: -1px;
}
ion-button.text-link ion-text {
  margin-inline-end: 0.5rem;
}
ion-button.text-large {
  --ion-button-padding-x: 0.5rem;
  white-space: normal;
}

ion-card {
  --background: var(--ion-card-background);
  --color: var(--ion-card-color);
  border-radius: var(--ion-card-border-radius, var(--theme-border-radius));
  margin-block: var(--ion-card-margin-block, 0.5rem);
  margin-inline: var(--ion-card-margin-inline, 0.5rem);
}
ion-card.custom {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1;
  text-align: center;
  height: 325px;
  border: 1px solid var(--ion-card-border-color);
}
ion-card.custom.selected {
  border: 4px solid var(--ion-card-border-color);
  background: var(--ion-color-light);
  color: var(--ion-color-dark);
}
ion-card.custom.selected ion-card-title {
  color: var(--ion-color-dark);
}
ion-checkbox {
  --border-color: var(--ion-color-light);
  --border-color-checked: var(--ion-color-dark);
  --border-radius: var(--theme-border-radius-circle);
  --checkbox-background-checked: var(--ion-color-light);
  --checkmark-color: var(--ion-color-dark);
  --checkmark-width: 0.1rem;
  --size: 1.5rem;
}
ion-checkbox.ion-dirty.ion-invalid::part(container) {
  --border-color: var(--ion-color-danger-tint);
  --checkbox-background: rgba(var(--ion-color-danger-rgb), .1);
}
ion-checkbox::part(container) {
  border-radius: var(--theme-border-radius-circle);
  align-self: self-start;
}

ion-content {
  --background: linear-gradient(172.26deg, #DFD7C8 3.4%, #EED498 24.16%, #ECD49D 47.78%, #DED7CA 93.59%) !important;
}
ion-content:not(.ion-no-padding) {
  --padding-start: var(--ion-padding, 16px);
  --padding-end: var(--ion-padding, 16px);
  --padding-top: var(--ion-padding, 16px);
  --padding-bottom: var(--ion-padding, 16px);
  -webkit-padding-start: var(--ion-padding, 16px);
  padding-inline-start: var(--ion-padding, 16px);
  -webkit-padding-end: var(--ion-padding, 16px);
  padding-inline-end: var(--ion-padding, 16px);
  padding-top: var(--ion-padding, 16px);
  padding-bottom: var(--ion-padding, 16px);
}

ion-grid.grid-no-padding {
  --ion-grid-padding: 0;
}

ion-item {
  --background: var(--ion-item-background);
  --background-activated: var(--ion-color-warning);
  --background-focused: var(--ion-item-background-activated);
  --background-focused-opacity: var(--ion-item-background-activated-opacity);
  --background-hover: var(--ion-item-background-hover);
  --border-color: rgba(var(--ion-color-warning-rgb), 0);
  --border-radius: var(--theme-border-radius);
  --border-style: solid;
  --border-width: 2px;
  --color: var(--ion-item-color);
  --inner-border-width: var(--ion-item-border-width);
  --inner-padding-end: var(--ion-item-padding-inline);
  --padding-start: 1rem;
}
ion-item.custom ion-label {
  font-size: 1.25rem;
}
ion-item.correct {
  opacity: 1;
  --border-color: var(--ion-color-success);
}
ion-item.wrong {
  --border-color: var(--ion-color-danger);
}
ion-item.wrong.item-disabled {
  opacity: 0.8;
}

ion-item-divider {
  --background: var(--ion-item-divider-background, currentColor);
  min-height: var(--ion-item-divider-min-height, initial);
}
ion-item-divider[color=light] {
  background-color: white;
  opacity: 0.32;
}

ion-menu {
  --background: #ddd2cd;
  --width: 70%;
}
ion-menu ion-content {
  --padding-top: 0;
  --background: var(--background);
}

ion-progress-bar {
  --background: var(--ion-progress-bar-background);
  --progress-background: var(--ion-progress-bar-progress-background);
}
ion-progress-bar.custom {
  height: 8px;
}

ion-radio {
  --color-checked: var(--ion-radio-color-checked);
}
ion-radio-group ion-item::part(native) {
  border: none;
  color: var(--ion-input-color);
  padding-inline: 1rem;
  border-radius: 0;
}
ion-radio-group ion-item::part(native):after {
  background: rgba(var(--ion-color-dark), 0.5);
}
ion-radio-group.custom {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.1875rem;
}
@media (min-width: 768px) {
  ion-radio-group.custom {
    justify-content: space-between;
  }
}
ion-radio-group.custom ion-radio::part(label) {
  --ion-radio-color-checked: var(--ion-color-light);
  color: var(--ion-color-dark);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  font-weight: var(--font-weight-semi-bold);
  font-size: 0.875rem;
}
ion-radio-group.custom ion-radio::part(container) {
  --ion-radio-background: var(--ion-color-light);
  --ion-radio-background-checked: var(--ion-color-tertiary);
  --color-checked: var(--ion-color-light);
  width: 2.25rem;
  height: 2.25rem;
  box-shadow: var(--form-input-box-shadow);
}
ion-radio-group.custom ion-radio.radio-checked::part(label) {
  color: var(--ion-radio-color-checked);
}
ion-radio-group.custom ion-radio::part(mark) {
  opacity: 0;
}
ion-radio::part(container) {
  background: var(--ion-radio-background);
}
ion-radio::part(mark) {
  height: var(--ion-radio-mark-size, 4px);
  width: var(--ion-radio-mark-size, 4px);
}
ion-radio::part(label) {
  white-space: normal;
}
ion-radio.radio-checked::part(container) {
  background: var(--ion-radio-background-checked);
}
ion-radio.correct::part(container) {
  background: var(--ion-radio-background-correct);
  --color-checked: var(--ion-radio-background-correct);
}
ion-radio.correct::part(container):after {
  content: "";
  width: 8px;
  height: 6px;
  background-image: url('data:image/svg+xml,<svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.06231 0.00197222C7.30534 0.0172534 7.53444 0.120684 7.70663 0.292861C7.87881 0.465054 7.98224 0.694153 7.99752 0.93718C8.0128 1.18021 7.93888 1.42046 7.78963 1.61286L7.70663 1.70686L3.70663 5.70686L3.61263 5.78986C3.43726 5.92593 3.2216 5.99978 2.99963 5.99978C2.77766 5.99978 2.56201 5.92593 2.38663 5.78986L2.29263 5.70686L0.292632 3.70686L0.209632 3.61286C0.0604867 3.42044 -0.0133434 3.18023 0.00198548 2.93726C0.0173139 2.6943 0.120749 2.46527 0.292894 2.29312C0.465039 2.12098 0.69407 2.01754 0.937036 2.00221C1.18 1.98689 1.42022 2.06072 1.61263 2.20986L1.70663 2.29286L2.99963 3.58486L6.29263 0.292861L6.38663 0.209861C6.57904 0.0606094 6.81929 -0.013309 7.06231 0.00197222Z" fill="%23D9D9D9"/></svg>');
  color: var(--color-checked);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
ion-radio.wrong::part(container) {
  background: var(--ion-radio-background-wrong);
  --color-checked: var(--ion-radio-background-wrong);
}
ion-radio.wrong::part(container):after {
  content: "";
  width: 6px;
  height: 6px;
  background-image: url('data:image/svg+xml,<svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.813944 0.0167184C1.05369 -0.0286918 1.30125 0.0152535 1.51121 0.140009L1.61277 0.210078L1.70701 0.293086L2.99998 1.58508L4.29295 0.293086C4.47312 0.113642 4.71433 0.00963828 4.96824 0.00182578C5.22215 -0.00598672 5.46971 0.0831246 5.66013 0.251337C5.85105 0.419306 5.97019 0.653681 5.99461 0.906367C6.01853 1.1593 5.94529 1.41198 5.79002 1.61291L5.70701 1.7069L4.41502 3.00012L5.70701 4.29309C5.88621 4.47302 5.99021 4.71447 5.99803 4.96838C6.00584 5.22228 5.91697 5.46984 5.74851 5.66027C5.58054 5.85095 5.34666 5.97033 5.09373 5.9945C4.8408 6.01843 4.58787 5.94543 4.38719 5.78991L4.29295 5.7069L2.99998 4.41491L1.70701 5.7069C1.52683 5.88635 1.28562 5.9906 1.03172 5.99816C0.989236 5.99938 0.946756 5.99792 0.904764 5.99377C0.696268 5.97375 0.498026 5.88854 0.339822 5.74865C0.148904 5.58068 0.0297638 5.34655 0.00534977 5.09362C-0.018576 4.84069 0.0546662 4.58801 0.20994 4.38708L0.292947 4.29309L1.58494 3.00012L0.292947 1.7069C0.233865 1.64782 0.182596 1.5819 0.140115 1.51086C0.059549 1.37536 0.0112091 1.2218 0.00144352 1.06213C-0.0136932 0.81872 0.0605256 0.577998 0.20994 0.385615C0.359354 0.192988 0.574197 0.0618844 0.813944 0.0167184Z" fill="%23D9D9D9"/></svg>');
  color: var(--color-checked);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

form ion-label {
  display: inline-block;
  color: var(--ion-color-dark);
  margin-bottom: 0.5rem;
  font-size: var(--ion-label-font-size);
}
form .append-item {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: var(--padding-end);
  font-size: var(--font-size-sm);
  color: var(--form-input-color);
  background: var(--ion-input-background);
  z-index: 3;
  margin: 0;
}
form ion-list {
  border-width: 0;
  padding: 0;
}
form ion-textarea {
  color: var(--ion-input-color) !important;
  background: var(--ion-input-background);
  box-shadow: var(--form-input-box-shadow);
  border-radius: var(--form-input-border-radius);
  border: var(--ion-input-border-width) var(--ion-input-border-style) var(--ion-input-border-color);
  padding-inline: 1rem;
  padding-bottom: 0.5rem;
}
form ion-input.custom {
  --background: var(--ion-input-background);
  --color: var(--ion-input-color);
  --border-color: var(--ion-input-border-color);
  --border-style: var(--ion-input-border-style);
  --border-radius: var(--ion-input-border-radius);
  --highlight-color-focused: var(--ion-color-success);
  --highlight-color-valid: var(--highlight-color-focused);
  --padding-top: 1rem;
  --padding-bottom: 1rem;
  --padding-end: var(--ion-input-padding-end);
  --padding-start: var(--ion-input-padding-start);
  --placeholder-color: var(--form-input-placeholder-color);
  --border-width: 0;
  border: var(--ion-input-border-width) var(--border-style) var(--border-color);
  border-radius: var(--border-radius);
  box-shadow: var(--form-input-box-shadow);
}
form ion-input.custom.input-disabled {
  opacity: 0.6;
}
form ion-input.custom.input-disabled input {
  opacity: 1;
}
form ion-input.custom .input-bottom {
  padding-top: 0;
}
form ion-input.custom .input-highlight {
  display: none;
}
form ion-checkbox {
  --border-radius: var(--ion-checkbox-border-radius);
}
form ion-select {
  --background: var(--ion-select-background);
  --border-color: var(--ion-select-border-color);
  --border-radius: var(--ion-select-border-radius);
  --border-style: var(--ion-select-border-style);
  --border-width: var(--ion-select-border-width);
  --highlight-color-valid: var(--border-color);
  --padding-bottom: 1rem;
  --padding-end: var(--ion-input-padding-end);
  --padding-start: var(--ion-input-padding-start);
  --padding-top: 1rem;
  --placeholder-color: var(--ion-select-color);
  --placeholder-opacity: 1;
  color: var(--ion-select-color);
  border-radius: var(--ion-select-border-radius);
  box-shadow: var(--form-input-box-shadow);
  border: var(--ion-select-border-width) var(--ion-select-border-style) var(--ion-select-border-color);
}
form ion-select::part(text), form ion-select::part(placeholder) {
  opacity: 1;
  text-align: left;
}
form ion-select::part(icon) {
  top: 0;
}
form:host(.select-fill-outline) .notch-spacer {
  display: none;
}

ion-header .header-logo {
  font-size: 4rem;
  width: 2.5em;
}
ion-header ion-button::part(native) {
  --padding-start: .125rem;
  --padding-end: .125rem;
}

img::part(image),
ion-img::part(image) {
  object-fit: cover;
}
img:not([src])::part(image),
ion-img:not([src])::part(image) {
  width: 100%;
  height: 100%;
}

ion-list ion-item {
  --inner-padding-end: var(--ion-list-item-padding-x, var(--ion-item-padding-inline));
  --inner-padding-start: var(--ion-list-item-padding-x, var(--ion-item-padding-inline));
  --padding-start: var(--ion-list-item-padding-x, var(--ion-item-padding-inline));
  text-align: inherit;
}
ion-list ion-item ion-label {
  margin-right: 1rem;
}
ion-list ion-item ion-icon {
  color: var(--ion-color-secondary);
}

ion-modal {
  --background: var(--ion-color-light);
  --min-width: 250px;
  color: var(--ion-color-primary);
}
ion-modal:not(.modal-dialog) {
  padding-top: var(--ion-safe-area-top);
}
ion-modal.modal-dialog {
  --max-width: 625px;
  --width: fit-content;
  --max-height: 832px;
  --border-radius: 6px;
  --overflow: initial;
}
ion-modal.modal-dialog h1, ion-modal.modal-dialog h2, ion-modal.modal-dialog h3, ion-modal.modal-dialog h4, ion-modal.modal-dialog h5, ion-modal.modal-dialog h6,
ion-modal.modal-dialog .h1, ion-modal.modal-dialog .h2, ion-modal.modal-dialog .h3, ion-modal.modal-dialog .h4, ion-modal.modal-dialog .h5, ion-modal.modal-dialog .h6 {
  color: var(--ion-color-primary);
}
ion-modal > .ion-page {
  overflow: scroll;
}
ion-modal::part(handle) {
  top: 1rem;
}
ion-modal.height-auto {
  --height: auto;
}
ion-modal.no-handle::part(handle) {
  display: none;
}
ion-modal.action-sheet::part(content) {
  border-top-left-radius: var(--theme-border-radius);
  border-top-right-radius: var(--theme-border-radius);
}

ion-segment.no-feedback ion-segment-button {
  --color: initial;
  --color-checked: initial;
  --indicator-color: initial;
  font-weight: initial;
}
ion-segment-button {
  text-transform: var(--ion-segment-button-text-transform);
}
ion-segment.md ion-segment-button {
  --color-checked: var(--ion-segment-button-indicator-color-checked);
  --indicator-color: var(--ion-segment-button-indicator-color);
}
ion-segment.md ion-segment-button:not(.segment-button-checked)::part(indicator) {
  opacity: 0.32;
}
ion-segment.md ion-segment-button.segment-button-checked {
  --indicator-color: var(--ion-segment-button-indicator-color-checked);
}
ion-segment.md ion-segment-button.segment-button-checked ion-icon,
ion-segment.md ion-segment-button.segment-button-checked ion-label {
  color: var(--ion-text-color);
}
ion-segment.ios {
  --background: var(--ion-segment-background);
  border-radius: var(--ion-segment-border-radius);
  padding-inline: var(--ion-segment-padding-x);
  padding: var(--ion-segment-padding-top, var(--ion-segment-padding-y)) var(--ion-segment-padding-end, var(--ion-segment-padding-x)) var(--ion-segment-padding-bottom, var(--ion-segment-padding-y)) var(--ion-segment-padding-start, var(--ion-segment-padding-x));
}
ion-segment.ios ion-segment-button {
  --background: var(--ion-segment-button-background);
  --border-radius: var(--ion-segment-button-border-radius);
  --border-width: var(--ion-segment-button-border-width);
  --color: var(--ion-segment-button-color);
  --color-checked: var(--ion-segment-button-color-checked);
  --indicator-box-shadow: var(--ion-segment-button-indicator-box-shadow);
  --indicator-color: var(--ion-segment-button-indicator-color);
  --padding-top: var(--ion-segment-button-padding-top, var(--ion-segment-button-padding-y));
  --padding-end: var(--ion-segment-button-padding-end, var(--ion-segment-button-padding-x));
  --padding-bottom: var(--ion-segment-button-padding-bottom, var(--ion-segment-button-padding-y));
  --padding-start: var(--ion-segment-button-padding-start, var(--ion-segment-button-padding-x));
}

ion-tab-bar {
  --border: none;
  height: 72px;
}
ion-tab-button {
  font-weight: 600;
  --ripple-color: var(--ion-color-primary);
}
ion-tab-button.tab-selected {
  border-radius: var(--theme-border-radius);
  --background: #a78b7d;
}

ion-toolbar {
  --background: var(--ion-toolbar-background);
  --padding-bottom: var(--ion-toolbar-padding-y);
  --padding-end: var(--ion-toolbar-padding-x);
  --padding-start: var(--ion-toolbar-padding-x);
  --padding-top: var(--ion-toolbar-padding-y);
  --ion-padding: var(--ion-toolbar-inline-padding);
  --ion-margin: var(--ion-toolbar-inline-margin);
}
ion-toolbar ion-title {
  font-size: var(--ion-toolbar-title-font-size);
}
ion-toolbar ion-title.ios {
  padding-inline: 50px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: var(--headings-font-family);
  font-weight: var(--headings-font-weight);
  color: var(--headings-color);
}
h1:first-child:not(.ion-margin-top):not(.ion-margin-vertical), h1:first-of-type:not(.ion-margin-top):not(.ion-margin-vertical),
h2:first-child:not(.ion-margin-top):not(.ion-margin-vertical),
h2:first-of-type:not(.ion-margin-top):not(.ion-margin-vertical),
h3:first-child:not(.ion-margin-top):not(.ion-margin-vertical),
h3:first-of-type:not(.ion-margin-top):not(.ion-margin-vertical),
h4:first-child:not(.ion-margin-top):not(.ion-margin-vertical),
h4:first-of-type:not(.ion-margin-top):not(.ion-margin-vertical),
h5:first-child:not(.ion-margin-top):not(.ion-margin-vertical),
h5:first-of-type:not(.ion-margin-top):not(.ion-margin-vertical),
h6:first-child:not(.ion-margin-top):not(.ion-margin-vertical),
h6:first-of-type:not(.ion-margin-top):not(.ion-margin-vertical),
.h1:first-child:not(.ion-margin-top):not(.ion-margin-vertical),
.h1:first-of-type:not(.ion-margin-top):not(.ion-margin-vertical),
.h2:first-child:not(.ion-margin-top):not(.ion-margin-vertical),
.h2:first-of-type:not(.ion-margin-top):not(.ion-margin-vertical),
.h3:first-child:not(.ion-margin-top):not(.ion-margin-vertical),
.h3:first-of-type:not(.ion-margin-top):not(.ion-margin-vertical),
.h4:first-child:not(.ion-margin-top):not(.ion-margin-vertical),
.h4:first-of-type:not(.ion-margin-top):not(.ion-margin-vertical),
.h5:first-child:not(.ion-margin-top):not(.ion-margin-vertical),
.h5:first-of-type:not(.ion-margin-top):not(.ion-margin-vertical),
.h6:first-child:not(.ion-margin-top):not(.ion-margin-vertical),
.h6:first-of-type:not(.ion-margin-top):not(.ion-margin-vertical) {
  margin-top: 0;
}
h1:only-child,
h2:only-child,
h3:only-child,
h4:only-child,
h5:only-child,
h6:only-child,
.h1:only-child,
.h2:only-child,
.h3:only-child,
.h4:only-child,
.h5:only-child,
.h6:only-child {
  margin-bottom: 0;
}

h1, .h1 {
  font-size: var(--h1-font-size);
}

h2, .h2 {
  font-size: var(--h2-font-size);
}

h3, .h3 {
  font-size: var(--h3-font-size);
}

h4, .h4 {
  font-size: var(--h4-font-size);
}

h5, .h5 {
  font-size: var(--h5-font-size);
}

h6, .h6 {
  font-size: var(--h6-font-size);
}

p {
  line-height: 1.6em;
}
p:last-child:not(.ion-margin-bottom):not(.ion-margin-vertical), p:last-of-type:not(.ion-margin-bottom):not(.ion-margin-vertical) {
  margin-bottom: 0;
}

.rounded {
  border-radius: var(--theme-border-radius);
}
.rounded::part(image) {
  border-radius: var(--theme-border-radius);
}
.rounded-circle {
  border-radius: var(--theme-border-radius-circle);
}
.rounded-circle::part(image) {
  border-radius: var(--theme-border-radius-circle);
}

.ion-no-border {
  --border-width: 0;
  --inner-border-width: 0;
}

hr {
  display: inline-block;
  width: 100%;
  background: var(--ion-color-light-shade);
}

.d-flex {
  display: flex;
}
.d-block {
  display: block;
}
.d-inline-block {
  display: inline-block;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-1q {
  flex-grow: 1.5;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-grow-3 {
  flex-grow: 3;
}

.flex-grow-4 {
  flex-grow: 4;
}

.flex-grow-5 {
  flex-grow: 5;
}

.flex-column {
  flex-direction: column;
}

@media (min-width: 768px) {
  .justify-content-md-center {
    justify-content: center;
  }
}

.m-auto {
  margin: auto;
  margin-inline: auto;
  margin-block: auto;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
  margin-inline: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
  margin-block: auto;
}
.ms-auto {
  margin-left: auto;
}
.mb-0 {
  margin-bottom: 0;
}

.pb-0q {
  padding-bottom: 0.25rem;
}

.pt-0q {
  padding-top: 0.25rem;
}

.ps-0q {
  padding-left: 0.25rem;
}

.pe-0q {
  padding-right: 0.25rem;
}

.px-0q {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.py-0q {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.pb-0h {
  padding-bottom: 0.5rem;
}

.pt-0h {
  padding-top: 0.5rem;
}

.ps-0h {
  padding-left: 0.5rem;
}

.pe-0h {
  padding-right: 0.5rem;
}

.px-0h {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.py-0h {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.pb-0hq {
  padding-bottom: 0.75rem;
}

.pt-0hq {
  padding-top: 0.75rem;
}

.ps-0hq {
  padding-left: 0.75rem;
}

.pe-0hq {
  padding-right: 0.75rem;
}

.px-0hq {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.py-0hq {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.pb-1 {
  padding-bottom: 1rem;
}

.pt-1 {
  padding-top: 1rem;
}

.ps-1 {
  padding-left: 1rem;
}

.pe-1 {
  padding-right: 1rem;
}

.px-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pb-2 {
  padding-bottom: 2rem;
}

.pt-2 {
  padding-top: 2rem;
}

.ps-2 {
  padding-left: 2rem;
}

.pe-2 {
  padding-right: 2rem;
}

.px-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pb-3 {
  padding-bottom: 3rem;
}

.pt-3 {
  padding-top: 3rem;
}

.ps-3 {
  padding-left: 3rem;
}

.pe-3 {
  padding-right: 3rem;
}

.px-3 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.py-3 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

@media (min-width: 768px) {
  .px-md-0 {
    padding-inline: 0;
  }
}

.position-static {
  position: static;
}
.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}
.position-fixed {
  position: fixed;
}
.position-sticky {
  position: sticky;
}

.ratio {
  display: block;
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--ion-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --ion-aspect-ratio: 100%;
}

.ratio-4x3 {
  --ion-aspect-ratio: 75%;
}

.ratio-16x9 {
  --ion-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --ion-aspect-ratio: 42.8571428571%;
}

.fw-regular {
  font-weight: 400;
}
.fw-semi-bold {
  font-weight: 600;
}
.fw-bold {
  font-weight: 700;
}
.fw-extra-bold {
  font-weight: 800;
}

.ff-body {
  font-family: "Open Sans", sans-serif;
}
.ff-heading {
  font-family: "Open Sans", sans-serif;
}

.fs-xs {
  font-size: 0.75rem;
}
.fs-sm {
  font-size: 0.875rem;
}
.fs-normal {
  font-size: 1rem;
}
.fs-lg {
  font-size: 1.25rem;
}
.fs-xl {
  font-size: 1.75rem;
}

.text-transform-initial {
  text-transform: initial;
}
.text-pre-line {
  white-space: pre-line;
}
.text-decoration-none {
  text-decoration: none;
}
.text-decoration-underline {
  text-decoration: underline;
}

.no-shadow {
  --box-shadow: none;
  box-shadow: none;
}

.cursor-pointer,
[ng-reflect-router-link],
[role=button],
ion-activatable {
  cursor: pointer;
}

.pointer-none {
  pointer-events: none;
}

.w-100 {
  width: 100%;
}

.logo {
  max-width: 316px;
  height: auto;
}

ion-content.no-scroll::part(scroll) {
  display: grid;
  align-items: center;
}
ion-content.no-scroll > section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}