h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: var(--headings-font-family);
  font-weight: var(--headings-font-weight);
  color: var(--headings-color);

  &:first-child,
  &:first-of-type {

    &:not(.ion-margin-top):not(.ion-margin-vertical) {
      margin-top: 0;
    }
  }

  &:only-child {
    margin-bottom: 0;
  }
}

h1, .h1 {
  font-size: var(--h1-font-size);
}

h2, .h2 {
  font-size: var(--h2-font-size);
}

h3, .h3 {
  font-size: var(--h3-font-size);
}

h4, .h4 {
  font-size: var(--h4-font-size);
}

h5, .h5 {
  font-size: var(--h5-font-size);
}

h6, .h6 {
  font-size: var(--h6-font-size);
}

// Paragraphs

p {

  line-height: 1.6em;

  &:last-child,
  &:last-of-type {

    &:not(.ion-margin-bottom):not(.ion-margin-vertical) {
      margin-bottom: 0;
    }
  }
}
