@import "../theme/@ionic/theme/ionic-mixins";

ion-radio {
  //--border-radius: ''; // Border radius of the radio
  //--color: var(--ion-color-warning); // Color of the radio
  --color-checked: var(--ion-radio-color-checked); // Color of the checked radio
  //--inner-border-radius: ''; // Border radius of the inner checked radio

  &-group {
    ion-item {

      &::part(native) {
        border: none;
        color: var(--ion-input-color);
        padding-inline: 1rem;
        border-radius: 0;

        &:after {
          background: rgba(var(--ion-color-dark), .5);
        }
      }
    }

    &.custom {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: .1875rem;

      @include media-breakpoint-up(md) {
        justify-content: space-between;
      }

     ion-radio {
       &::part(label) {
         --ion-radio-color-checked: var(--ion-color-light);

         color: var(--ion-color-dark);
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
         z-index: 1;
         font-weight: var(--font-weight-semi-bold);
         font-size: $font-size-sm;
       }

       &::part(container) {
         --ion-radio-background: var(--ion-color-light);
         --ion-radio-background-checked: var(--ion-color-tertiary);
         --color-checked: var(--ion-color-light);

         width: 2.25rem;
         height: 2.25rem;
         box-shadow: var(--form-input-box-shadow);
       }

       &.radio-checked {

         &::part(label) {
           color: var(--ion-radio-color-checked);
         }
       }

       &::part(mark) {
         opacity: 0;
       }
     }
    }
  }

  &::part(container) {
    //border: #{$border-width * 2.75} solid var(--ion-color-tertiary);
    background: var(--ion-radio-background);
  }

  &::part(mark) {
    height: var(--ion-radio-mark-size, 4px);
    width: var(--ion-radio-mark-size, 4px);
  }

  &::part(label) {
    white-space: normal;
  }

  // App specific (should not be in starter)
  &.radio-checked {

    &::part(container) {
      background: var(--ion-radio-background-checked);
      // border-color: var(--ion-radio-background);
    }
  }

  &.correct{
    &::part(container) {
      background: var(--ion-radio-background-correct);
      // border-color: var(--ion-radio-background);
      --color-checked: var(--ion-radio-background-correct);
      &:after {
        content: '';
        width: 8px;
        height: 6px;
        background-image: url('data:image/svg+xml,<svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.06231 0.00197222C7.30534 0.0172534 7.53444 0.120684 7.70663 0.292861C7.87881 0.465054 7.98224 0.694153 7.99752 0.93718C8.0128 1.18021 7.93888 1.42046 7.78963 1.61286L7.70663 1.70686L3.70663 5.70686L3.61263 5.78986C3.43726 5.92593 3.2216 5.99978 2.99963 5.99978C2.77766 5.99978 2.56201 5.92593 2.38663 5.78986L2.29263 5.70686L0.292632 3.70686L0.209632 3.61286C0.0604867 3.42044 -0.0133434 3.18023 0.00198548 2.93726C0.0173139 2.6943 0.120749 2.46527 0.292894 2.29312C0.465039 2.12098 0.69407 2.01754 0.937036 2.00221C1.18 1.98689 1.42022 2.06072 1.61263 2.20986L1.70663 2.29286L2.99963 3.58486L6.29263 0.292861L6.38663 0.209861C6.57904 0.0606094 6.81929 -0.013309 7.06231 0.00197222Z" fill="%23D9D9D9"/></svg>');
        color: var(--color-checked);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &.wrong{
    &::part(container) {
      background: var(--ion-radio-background-wrong);
      // border-color: var(--ion-radio-background);
      --color-checked: var(--ion-radio-background-wrong);
      &:after {
        content: '';
        width: 6px;
        height: 6px;
        background-image: url('data:image/svg+xml,<svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.813944 0.0167184C1.05369 -0.0286918 1.30125 0.0152535 1.51121 0.140009L1.61277 0.210078L1.70701 0.293086L2.99998 1.58508L4.29295 0.293086C4.47312 0.113642 4.71433 0.00963828 4.96824 0.00182578C5.22215 -0.00598672 5.46971 0.0831246 5.66013 0.251337C5.85105 0.419306 5.97019 0.653681 5.99461 0.906367C6.01853 1.1593 5.94529 1.41198 5.79002 1.61291L5.70701 1.7069L4.41502 3.00012L5.70701 4.29309C5.88621 4.47302 5.99021 4.71447 5.99803 4.96838C6.00584 5.22228 5.91697 5.46984 5.74851 5.66027C5.58054 5.85095 5.34666 5.97033 5.09373 5.9945C4.8408 6.01843 4.58787 5.94543 4.38719 5.78991L4.29295 5.7069L2.99998 4.41491L1.70701 5.7069C1.52683 5.88635 1.28562 5.9906 1.03172 5.99816C0.989236 5.99938 0.946756 5.99792 0.904764 5.99377C0.696268 5.97375 0.498026 5.88854 0.339822 5.74865C0.148904 5.58068 0.0297638 5.34655 0.00534977 5.09362C-0.018576 4.84069 0.0546662 4.58801 0.20994 4.38708L0.292947 4.29309L1.58494 3.00012L0.292947 1.7069C0.233865 1.64782 0.182596 1.5819 0.140115 1.51086C0.059549 1.37536 0.0112091 1.2218 0.00144352 1.06213C-0.0136932 0.81872 0.0605256 0.577998 0.20994 0.385615C0.359354 0.192988 0.574197 0.0618844 0.813944 0.0167184Z" fill="%23D9D9D9"/></svg>');
        color: var(--color-checked);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
