ion-content {

  --background: linear-gradient(172.26deg, #DFD7C8 3.4%, #EED498 24.16%, #ECD49D 47.78%, #DED7CA 93.59%) !important;

  &:not(.ion-no-padding) {
    --padding-start: var(--ion-padding, 16px);
    --padding-end: var(--ion-padding, 16px);
    --padding-top: var(--ion-padding, 16px);
    --padding-bottom: var(--ion-padding, 16px);
    -webkit-padding-start: var(--ion-padding, 16px);
    padding-inline-start: var(--ion-padding, 16px);
    -webkit-padding-end: var(--ion-padding, 16px);
    padding-inline-end: var(--ion-padding, 16px);
    padding-top: var(--ion-padding, 16px);
    padding-bottom: var(--ion-padding, 16px);
  }
}
