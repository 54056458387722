ion-card {
  --background: var(--ion-card-background); // Background of the card
  --color: var(--ion-card-color); // Color of the card
  //box-shadow: var(--ion-card-box-shadow);
  border-radius: var(--ion-card-border-radius, var(--theme-border-radius));
  margin-block: var(--ion-card-margin-block, 0.5rem);
  margin-inline: var(--ion-card-margin-inline, 0.5rem);

  &.custom {
    position: relative;
    display: flex;
    align-items: center;
    z-index: 1;
    text-align: center;
    height: 325px;
    border: 1px solid var(--ion-card-border-color);

    &.selected {
      border: 4px solid var(--ion-card-border-color);
      background: var(--ion-color-light);
      color: var(--ion-color-dark);

      ion-card-title{
        color: var(--ion-color-dark);
      }
    }
  }

  &-subtitle {

  }

  &-title {
  }
}
