ion-action-sheet {
  &.custom {
    //--backdrop-opacity: ''; // Opacity of the backdrop
    //--background: ''; // Background of the action sheet group
    //--button-background: ''; // Background of the action sheet button
    //--button-background-activated: ''; // Background of the action sheet button when pressed. Note: setting this will interfere with the Material Design ripple.
    //--button-background-activated-opacity: ''; // Opacity of the action sheet button background when pressed
    //--button-background-focused: ''; // Background of the action sheet button when tabbed to
    //--button-background-focused-opacity: ''; // Opacity of the action sheet button background when tabbed to
    //--button-background-hover: ''; // Background of the action sheet button on hover
    //--button-background-hover-opacity: ''; // Opacity of the action sheet button background on hover
    //--button-background-selected: ''; // Background of the selected action sheet button
    //--button-background-selected-opacity: ''; // Opacity of the selected action sheet button background
    //--button-color: ''; // Color of the action sheet button
    //--button-color-activated: ''; // Color of the action sheet button when pressed
    //--button-color-focused: ''; // Color of the action sheet button when tabbed to
    //--button-color-hover: ''; // Color of the action sheet button on hover
    --button-color-selected: var(--ion-color-secondary); // Color of the selected action sheet button
    //--color: ''; // Color of the action sheet text
    //--height: ''; // height of the action sheet
    //--max-height: ''; // Maximum height of the action sheet
    //--max-width: ''; // Maximum width of the action sheet
    //--min-height: ''; // Minimum height of the action sheet
    //--min-width: ''; // Minimum width of the action sheet
    //--width: ''; // Width of the action sheet
  }
}
