ion-tabs {

}

ion-tab {

  &-bar {
    //--background: var(--ion-color-primary); // Background of the tab bar
    --border: none; // Border of the tab bar
    //--color: var(--ion-color-secondary); // Color of the tab bar
    //box-shadow: var(--ion-tab-bar-box-shadow);

    // This height needed because ion-tab-bar is an absolute layout (root
    // layout), so it MUST have a declared height
    height: 72px;
  }

  &-button {
    font-weight: #{$font-weight-semi-bold};
    //--background: ''; // Background of the tab button
    //--background-focused: ''; // Background of the tab button when focused with the tab key
    //--background-focused-opacity: ''; // Opacity of the tab button background when focused with the tab key
    //--color: ''; // Color of the tab button
    //--color-focused: ''; // Color of the tab button when focused with the tab key
    //--color-selected: ''; // Color of the selected tab button
    //--padding-bottom: ''; // Bottom padding of the tab button
    //--padding-end: ''; // Right padding if direction is left-to-right, and left padding if direction is right-to-left of the tab button
    //--padding-start: ''; // Left padding if direction is left-to-right, and right padding if direction is right-to-left of the tab button
    //--padding-top: ''; // Top padding of the tab button
    --ripple-color: var(--ion-color-primary); // Color of the button ripple effect

    &.tab-selected {
      border-radius: var(--theme-border-radius);
      --background: #{tint-color($primary, $tint-level-2)};
    }
  }
}
