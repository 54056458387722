// Font Weight
$font-weight-thin: 100;
$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-black: 900;

// Font Size
$font-size-xs: .75rem; // 12px
$font-size-sm: .875rem; // 14px
$default-font-size: 1rem; // 16px
$font-size-lg: 1.25rem; // 20px
$font-size-xl: 1.75rem; // 24px

// Font Family
$default-font-family: 'Open Sans', sans-serif;

// Headings
$headings-font-family: $default-font-family;
$headings-font-weight: $font-weight-semi-bold;
$headings-color: $primary;

// Heading size
$h1-font-size: 2.125rem; // 34px
$h2-font-size: $font-size-xl; // 24px
$h3-font-size: $font-size-lg; // 20px
$h4-font-size: .875rem; // 14px

// CSS

:root {
  --font-weight-thin: #{$font-weight-thin};
  --font-weight-extra-light: #{$font-weight-extra-light};
  --font-weight-light: #{$font-weight-light};
  --font-weight-regular: #{$font-weight-regular};
  --font-weight-medium: #{$font-weight-medium};
  --font-weight-semi-bold: #{$font-weight-semi-bold};
  --font-weight-bold: #{$font-weight-bold};
  --font-weight-extra-bold: #{$font-weight-extra-bold};
  --font-weight-black: #{$font-weight-black};

  --font-size-default: #{$default-font-size};
  --font-size-xs: #{$font-size-xs};
  --font-size-sm: #{$font-size-sm};
  --font-size-lg: #{$font-size-lg};
  --font-size-xl: #{$font-size-xl};
  --h1-font-size: #{$h1-font-size};
  --h2-font-size: #{$h2-font-size};
  --h3-font-size: #{$h3-font-size};
  --h4-font-size: #{$h4-font-size};

  --headings-font-family: #{$default-font-family};
  --headings-font-weight: #{$headings-font-weight};
  --headings-color: #{$headings-color};
}

