.cursor-pointer,
[ng-reflect-router-link],
[role='button'],
ion-activatable {
  cursor: pointer;
}

.pointer-none {
  pointer-events: none;
}

.w-100 {
  width: 100%;
}
