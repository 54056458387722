ion-checkbox {
  --border-color: var(--ion-color-light); // Border color of the checkbox icon
  --border-color-checked: var(--ion-color-dark); // Border color of the checkbox icon when checked
  --border-radius: var(--theme-border-radius-circle); // Border radius of the checkbox icon
  //--border-style: ''; // Border style of the checkbox icon
  //--border-width: ''; // Border width of the checkbox icon
  //--checkbox-background: ''; // Background of the checkbox icon
  --checkbox-background-checked: var(--ion-color-light); // Background of the checkbox icon when checked
  --checkmark-color: var(--ion-color-dark); // Color of the checkbox checkmark when checked
  --checkmark-width: 0.1rem; // Stroke width of the checkbox checkmark
  --size: 1.5rem; // Size of the checkbox icon
  //--transition: ''; // Transition of the checkbox icon

  &.ion-dirty.ion-invalid {
    &::part(container) {
      --border-color: var(--ion-color-danger-tint);
      --checkbox-background: rgba(var(--ion-color-danger-rgb), .1);
    }
  }

  &::part(container) {
    border-radius: var(--theme-border-radius-circle);
    align-self: self-start;
  }
}
