// Body
$body-color: $white;
$body-color-rgb: to-rgb($body-color);
$body-bg: $primary;
$body-bg-rgb: to-rgb($body-bg);

// ion-input
$enable-input-around-border: true;

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #561F05;
  --ion-color-primary-rgb: 86,31,5;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #4c1b04;
  --ion-color-primary-tint: #67351e;

  /** secondary **/
  --ion-color-secondary: #AF8C4B;
  --ion-color-secondary-rgb: 175,140,75;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #9a7b42;
  --ion-color-secondary-tint: #FBF6EC;

  /** tertiary **/
  --ion-color-tertiary: #D31212;
  --ion-color-tertiary-rgb: 211,18,18;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #ba1010;
  --ion-color-tertiary-tint: #d72a2a;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #BFA36F;
  --ion-color-warning-rgb: 191,163,111;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #a88f62;
  --ion-color-warning-tint: #c5ac7d;

  /** danger **/
  --ion-color-danger: #D32A3A;
  --ion-color-danger-rgb: 211,42,58;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #ba2533;
  --ion-color-danger-tint: #d73f4e;

  /** dark **/
  --ion-color-dark: #595959;
  --ion-color-dark-rgb: 89,89,89;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #4e4e4e;
  --ion-color-dark-tint: #6a6a6a;

  /** medium **/
  --ion-color-medium: #A1A1A1;
  --ion-color-medium-rgb: 161,161,161;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #8e8e8e;
  --ion-color-medium-tint: #aaaaaa;

  /** light **/
  --ion-color-light: #FFFFFF;
  --ion-color-light-rgb: 255,255,255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #e0e0e0;
  --ion-color-light-tint: #ffffff;

  // Ionic App Theme CSS Variables
  --ion-text-color: #{$body-color}; // Text color of the entire app
  --ion-text-color-rgb: #{$body-color-rgb}; // Text color of the entire app, rgb format

  // App Theme CSS Variables - this variables is not default Ionic theme
  // it's from F&MD App Starter
  --theme-border-radius: #{$border-radius};
  --theme-border-radius-lg: #{$border-radius-lg};
  --theme-border-radius-circle: #{$border-radius-pill};
  --theme-border-width: #{$border-width};
  --theme-border-style: solid;
  --theme-border-color: #{$light};

  // ion-content
  --theme-padding: 1rem;
  --theme-margin: 1rem;

  // ion-button
  --ion-button-font-weight: #{$font-weight-bold};
  --ion-button-font-size: #{$font-size-sm};
  --ion-button-text-transform: uppercase;
  --ion-button-border-radius: .5rem;
  --ion-button-border-radius-circle: #{$border-radius-pill};
  --ion-button-border-width: var(--theme-border-width);
  --ion-button-box-shadow: #{$box-shadow};
  --ion-button-padding-x: 1.5rem;
  --ion-button-padding-y: var(--ion-button-padding-x);
  --ion-button-color: var(--ion-color-secondary);
  --ion-button-background: #{$light};

  // Forms
  --form-input-bg: var(--ion-color-light);
  --form-input-bg-over-color: transparent;
  --form-input-color: var(--ion-color-dark);
  --form-input-color-over-color: var(--ion-color-light);
  --form-input-border-color: var(--form-input-color);
  --form-input-border-width: var(--theme-border-width);
  --form-input-border-style: var(--theme-border-style);
  --form-input-border-radius: var(--theme-border-radius);
  --form-input-box-shadow: 0 4px 8px rgba(var(--ion-color-medium-contrast-rgb), .08);
  --form-input-placeholder-color: var(--ion-color-medium);

  // ion-label
  --ion-label-font-size: #{$font-size-xs};

  // ion-input
  --ion-input-background: var(--form-input-bg);
  --ion-input-color: var(--form-input-color);
  --ion-input-border-width: var(--form-input-border-width);
  --ion-input-border-color: var(--form-input-border-color);
  --ion-input-border-style: var(--form-input-border-style);
  --ion-input-border-radius: var(--form-input-border-radius);
  --form-ion-input-bg-over-color: var(--form-input-bg-over-color);
  --ion-input-color-over-color: var(--ion-color-white);
  --ion-input-placeholder-color-over-color: var(--ion-color-white);
  --ion-input-padding-start: 1rem;
  --ion-input-padding-end: 1rem;

  // ion-checkbox
  --ion-checkbox-border-width: var(--form-input-border-width);
  --ion-checkbox-border-style: var(--form-input-border-style);
  --ion-checkbox-border-radius: var(--form-input-border-radius);
  --ion-checkbox-bg-over-color: var(--form-input-bg-over-color);
  --ion-checkbox-border-color-over-color: inherit;

  // ion-radio
  --ion-radio-mark-size: 4px;
  --ion-radio-background: transparent;
  --ion-radio-background-checked: var(--ion-color-warning);
  --ion-radio-color-checked: var(--ion-color-dark);
  --ion-radio-background-correct: var(--ion-color-primary);
  --ion-radio-background-wrong: var(--ion-color-danger);

  // ion-select
  --ion-select-color: var(--ion-input-color);
  --ion-select-background: var(--ion-input-background);
  --ion-select-border-width: var(--form-input-border-width);
  --ion-select-border-color: var(--ion-input-border-color);
  --ion-select-border-style: var(--form-input-border-style);
  --ion-select-border-radius: var(--form-input-border-radius);

  --ion-item-background: rgba(var(--ion-color-primary-rgb), 0);
}

html {
  /*
   * For more information on dynamic font scaling, visit the documentation:
   * https://ionicframework.com/docs/layout/dynamic-font-scaling
   */
  --ion-dynamic-font: var(--ion-default-dynamic-font);
}
