ion-content {

  &.no-scroll {

    &::part(scroll) {
      display: grid;
      align-items: center;
    }

    > section {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
