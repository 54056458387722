@import "../theme/@ionic/theme/ionic-mixins";

$padding-size: (
 '0q':0.25rem,
 '0h':0.5rem,
 '0hq':0.75rem,
 '1':1rem,
 '2':2rem,
 '3':3rem,
);

@each $key, $value in $padding-size {
  .pb-#{$key}{
    padding-bottom: #{$value};
  }

  .pt-#{$key}{
    padding-top: #{$value};
  }

  .ps-#{$key}{
    padding-left: #{$value};
  }

  .pe-#{$key}{
    padding-right: #{$value};
  }

  .px-#{$key}{
    padding-left: #{$value};
    padding-right: #{$value};
  }

  .py-#{$key}{
    padding-top: #{$value};
    padding-bottom: #{$value};
  }
}

.px {
  &-md-0 {
    @include media-breakpoint-up(md) {
      padding-inline: 0;
    }
  }
}
