.m {

  &-auto {
    margin: auto;
    margin-inline: auto;
    margin-block: auto;
  }

  &x {

    &-auto {
      margin-left: auto;
      margin-right: auto;
      margin-inline: auto;
    }
  }

  &y {

    &-auto {
      margin-top: auto;
      margin-bottom: auto;
      margin-block: auto;
    }
  }

  &s {

    &-auto {
      margin-left: auto;
    }
  }

  &b {
    &-0 {
      margin-bottom: 0;
    }
  }
}
