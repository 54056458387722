ion-button {

  --background: var(--ion-button-background); // Background of the button
  //--background-activated: ''; // Background of the button when pressed. Note: setting this will interfere with the Material Design ripple.
  //--background-activated-opacity: ''; // Opacity of the button when pressed
  //--background-focused: ''; // Background of the button when focused with the tab key
  //--background-focused-opacity: ''; // Opacity of the button when focused with the tab key
  //--background-hover: ''; // Background of the button on hover
  //--background-hover-opacity: ''; // Opacity of the background on hover
  //--border-color: ''; // Border color of the button
  --border-radius: var(--ion-button-border-radius); // Border radius of the button
  //--border-style: ''; // Border style of the button
  --border-width: var(--ion-button-border-width); // Border width of the button
  --box-shadow: ''; // Box shadow of the button
  --color: var(--ion-button-color); // Text color of the button
  //--color-activated: ''; // Text color of the button when pressed
  //--color-focused: ''; // Text color of the button when focused with the tab key
  //--color-hover: ''; // Text color of the button when hover
  //--opacity: ''; // Opacity of the button
  --padding-bottom: 1rem; // Bottom padding of the button
  --padding-end: 1.5rem; // Right padding if direction is left-to-right, and left padding if direction is right-to-left of the button
  --padding-start: 1.5rem; // Left padding if direction is left-to-right, and right padding if direction is right-to-left of the button
  --padding-top: 1rem; // Top padding of the button
  //--ripple-color: ''; // Color of the button ripple effect
  //--transition: ''; // Transition of the button

  text-transform: var(--ion-button-text-transform);
  font-weight: var(--ion-button-font-weight);

  &.button-disabled {
    color: var(--ion-color-medium);
  }

  &:not(.ion-no-padding):not(.button-small):not(.button-large) {
    height: auto;
    font-size: var(--ion-button-font-size);
    --padding-top: var(--ion-button-padding-y);
    --padding-end: var(--ion-button-padding-x);
    --padding-bottom: var(--ion-button-padding-y);
    --padding-start: var(--ion-button-padding-x);
  }

  &:not([shape="round"]) {
  }

  &[shape="round"] {
  }

  &.outline {
    --background: var(--ion-background-color);
    --border-color: var(--ion-text-color);
    --border-style: solid;
    --border-width: 1px;
    --color: var(--ion-text-color);
  }

  &.text-link {
    --ion-button-padding-x: 0;
    vertical-align: middle;
    position: relative;
    top: -1px;

    ion-text {
      margin-inline-end: .5rem;
    }
  }

  &.text-large {
    --ion-button-padding-x: 0.5rem;
    white-space: normal;
  }
}
