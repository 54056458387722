@import "../theme/@ionic/theme/ionic-mixins";

$flex-grow-relations: (
  1:1,
  '1q':1.5,
  2:2,
  3:3,
  4:4,
  5:5,
);

@each $key, $value in $flex-grow-relations {
  .flex-grow-#{$key} {
    flex-grow: #{$value};
  }
}

.flex {
  &-column {
    flex-direction: column;
  }
}

.justify-content {
  &-md-center {
    @include media-breakpoint-up(md) {
      justify-content: center;
    }
  }
}

