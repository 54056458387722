ion-item-divider {
  --background: var(--ion-item-divider-background, currentColor); // Background of the item divider
  //--color: ''; // Color of the item divider
  //--inner-padding-bottom: ''; // Bottom inner padding of the item divider
  //--inner-padding-end: ''; // End inner padding of the item divider
  //--inner-padding-start: ''; // Start inner padding of the item divider
  //--inner-padding-top: ''; // Top inner padding of the item divider
  //--padding-bottom: ''; // Bottom padding of the item divider
  //--padding-end: ''; // Right padding if direction is left-to-right, and left padding if direction is right-to-left of the item divider
  //--padding-start: ''; // Left padding if direction is left-to-right, and right padding if direction is right-to-left of the item divider
  //--padding-top: ''; // Top padding of the item divider
  min-height: var(--ion-item-divider-min-height, initial);

  &[color="light"] {
    background-color: tint-color($light, $shade-level-3);
    opacity: $opacity-level-medium;
  }
}
