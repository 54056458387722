ion-item {
  --background: var(--ion-item-background); // Background of the item
  --background-activated: var(--ion-color-warning); // Background of the item when pressed. Note: setting this will interfere with the Material Design ripple.
  //--background-activated-opacity: ''; // Opacity of the item background when pressed
  --background-focused: var(--ion-item-background-activated); // Background of the item when focused with the tab key
  --background-focused-opacity: var(--ion-item-background-activated-opacity); // Opacity of the item background when focused with the tab key
  --background-hover: var(--ion-item-background-hover); // Background of the item on hover
  //--background-hover-opacity: ''; // Opacity of the background of the item on hover
  --border-color: rgba(var(--ion-color-warning-rgb), 0); // Color of the item border
  --border-radius: var(--theme-border-radius); // Radius of the item border
  --border-style: solid; // Style of the item border
  --border-width: 2px; // Width of the item border
  --color: var(--ion-item-color); // Color of the item
  //--color-activated: ''; // Color of the item when pressed
  //--color-focused: ''; // Color of the item when focused with the tab key
  //--color-hover: ''; // Color of the item on hover
  //--detail-icon-color: var(--ion-item-detail-color); // Color of the item detail icon
  //--detail-icon-font-size: ''; // Font size of the item detail icon
  //--detail-icon-opacity: var(--ion-item-detail-opacity); // Opacity of the item detail icon
  //--highlight-color-focused: ''; // The color of the highlight on the item when focused
  //--highlight-color-invalid: ''; // The color of the highlight on the item when invalid
  //--highlight-color-valid: ''; // The color of the highlight on the item when valid
  //--highlight-height: ''; // The height of the highlight on the item
  --inner-border-width: var(--ion-item-border-width); // Width of the item inner border
  //--inner-box-shadow: ''; // Box shadow of the item inner
  //--inner-padding-bottom: ''; // Bottom padding of the item inner
  --inner-padding-end: var(--ion-item-padding-inline); // Right padding if direction is left-to-right, and left padding if direction is right-to-left of the item inner
  //--inner-padding-start: ''; // Left padding if direction is left-to-right, and right padding if direction is right-to-left of the item inner
  //--inner-padding-top: ''; // Top padding of the item inner
  //--min-height: ''; // Minimum height of the item
  //--padding-bottom: ''; // Bottom padding of the item
  //--padding-end: ''; // Right padding if direction is left-to-right, and left padding if direction is right-to-left of the item
  --padding-start: 1rem; // Left padding if direction is left-to-right, and right padding if direction is right-to-left of the item
  //--padding-top: ''; // Top padding of the item
  //--ripple-color: ''; // Color of the item ripple effect
  //--transition: ''; // Transition of the item

  &.custom {

    ion-label {
      font-size: 1.25rem;
    }
  }

  &.correct {
    opacity: 1;
    --border-color: var(--ion-color-success);
  }

  &.wrong {
    --border-color: var(--ion-color-danger);
    &.item-disabled {
      opacity: .8;
    }
  }
}
