form {

  ion-label {
    display: inline-block;
    color: var(--ion-color-dark);
    margin-bottom: .5rem;
    font-size: var(--ion-label-font-size);
  }

  .append-item {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: var(--padding-end);
    font-size: var(--font-size-sm);
    color: var(--form-input-color);
    background: var(--ion-input-background);
    z-index: 3;
    // reboot
    margin: 0;
  }

  ion-list {
    border-width: 0;
    padding: 0;
  }

  ion-textarea {
    color: var(--ion-input-color) !important;
    background: var(--ion-input-background);
    box-shadow: var(--form-input-box-shadow);
    border-radius: var(--form-input-border-radius);
    border: var(--ion-input-border-width) var(--ion-input-border-style) var(--ion-input-border-color);
    padding-inline: 1rem;
    padding-bottom: .5rem;
  }

  ion-input {

    &.custom {
      --background: var(--ion-input-background);	// Background of the input
      --color: var(--ion-input-color); // Color of the input text
      --border-color: var(--ion-input-border-color); // Color of the border below the input when using helper text, error text, or counter
      --border-style: var(--ion-input-border-style); // Style of the border below the input when using helper text, error text, or counter
      --border-radius: var(--ion-input-border-radius); // Radius of the input
      --highlight-color-focused: var(--ion-color-success); // The color of the highlight on the input when focused
      //--highlight-color-invalid: '';	// The color of the highlight on the input when invalid
      --highlight-color-valid: var(--highlight-color-focused); // The color of the highlight on the input when valid
      --padding-top: 1rem;	// Bottom padding of the input
      --padding-bottom: 1rem;	// Bottom padding of the input
      --padding-end: var(--ion-input-padding-end); // Right padding if direction is left-to-right, and left padding if direction is right-to-left of the input
      --padding-start: var(--ion-input-padding-start); // Left padding if direction is left-to-right, and right padding if direction is right-to-left of the input
      //--padding-top: '';	// Top padding of the input
      --placeholder-color: var(--form-input-placeholder-color); // Color of the input placeholder text
      //--placeholder-font-style: '';	// Font style of the input placeholder text
      //--placeholder-font-weight: '';	// Font weight of the input placeholder text
      //--placeholder-opacity: '';	// Opacity of the input placeholder text

      --border-width: 0; // Width of the border below the input when using helper text, error text, or counter

      // If you need border all around the input, use this
      @if ($enable-input-around-border) {
        border: var(--ion-input-border-width) var(--border-style) var(--border-color);
        border-radius: var(--border-radius);
      }

      box-shadow: var(--form-input-box-shadow);

      &.input-disabled {
        opacity: .6;
        input {
          opacity: 1;
        }
      }

      // Border below the input when using helper text, error text, or counter
      .input-bottom {
        // to align the border with the input bottom line (if any)
        padding-top: 0;
      }

      .input-highlight {
        display: none;
      }
    }
  }

  ion-checkbox {
    --border-radius: var(--ion-checkbox-border-radius);
  }

  ion-select {
    --background: var(--ion-select-background); // Background of the select
    --border-color: var(--ion-select-border-color); // Color of the select border
    --border-radius: var(--ion-select-border-radius); // Radius of the select border
    --border-style: var(--ion-select-border-style); // Style of the select border
    --border-width: var(--ion-select-border-width); // Width of the select border
    //--highlight-color-focused: ''; // The color of the highlight on the select when focused
    //--highlight-color-invalid: ''; // The color of the highlight on the select when invalid
    --highlight-color-valid: var(--border-color); // The color of the highlight on the select when valid
    --padding-bottom: 1rem; // Bottom padding of the select
    --padding-end: var(--ion-input-padding-end); // Right padding if direction is left-to-right, and left padding if direction is right-to-left of the select
    --padding-start: var(--ion-input-padding-start); // Left padding if direction is left-to-right, and right padding if direction is right-to-left of the select
    --padding-top: 1rem; // Top padding of the select
    --placeholder-color: var(--ion-select-color); // Color of the select placeholder text
    --placeholder-opacity: 1; // Opacity of the select placeholder text
    //--ripple-color: ''; // The color of the ripple effect on MD mode.

    color:  var(--ion-select-color);
    border-radius:  var(--ion-select-border-radius);
    box-shadow: var(--form-input-box-shadow);
    border: var(--ion-select-border-width) var(--ion-select-border-style) var(--ion-select-border-color);

    &::part(text),
    &::part(placeholder) {
      opacity: 1;
      text-align: left;
    }

    &::part(icon) {
      top: 0;
    }
  }

  &.over-color {

    ion-input {
    }

    ion-checkbox {
    }
  }

  &:host(.select-fill-outline) .notch-spacer {
    display: none;
  }
}
