img,
ion-img {

  &::part(image) {
    object-fit: cover;
  }

  &:not([src]) {

    &::part(image) {
      //background: var(--ion-color-light);
      width: 100%;
      height: 100%;
    }
  }
}
